@import 'src/assets/css/vars.scss';

.wrapper {
  background-color: $whiteColor;
  padding: 8px 12px;
  margin-bottom: 42px;

  @media screen and (min-width: $screenMdMin) {
    padding: 22px 42px;
  }
}

.list {
  @media screen and (min-width: $screenMdMin) {
    columns: 2;
  }
}

.item {
  padding: 8px 0;
}

.itemLabel {
  position: relative;
  font-size: 16px;
  padding-left: 36px;
  cursor: pointer;
}

.itemCheckbox {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.itemCheckmark {
  position: absolute;
  top: 1px;
  left: 0;
  z-index: 1;
  vertical-align: middle;
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: 1px solid #ab9393;
  background-color: $whiteColor;
  transition: border-color 0.13s linear;

  input:checked + & {
    border-color: $bodyTextColor;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9InVpbTpjaGVjayI+CjxwYXRoIGlkPSJWZWN0b3IiIGQ9Ik03LjM3OTk0IDEyLjgxQzcuMjgxNDQgMTIuODEwMSA3LjE4Mzg5IDEyLjc5MDcgNy4wOTI4OSAxMi43NTNDNy4wMDE4OSAxMi43MTUzIDYuOTE5MjQgMTIuNjYgNi44NDk2OSAxMi41OTAzTDMuOTY5NjkgOS43MTAyNUMzLjg5ODA2IDkuNjQxMDcgMy44NDA5MiA5LjU1ODMxIDMuODAxNjEgOS40NjY4MUMzLjc2MjMxIDkuMzc1MyAzLjc0MTYyIDkuMjc2ODkgMy43NDA3NSA5LjE3NzNDMy43Mzk4OSA5LjA3NzcyIDMuNzU4ODYgOC45Nzg5NiAzLjc5NjU3IDguODg2NzlDMy44MzQyOCA4Ljc5NDYxIDMuODg5OTcgOC43MTA4OCAzLjk2MDM5IDguNjQwNDZDNC4wMzA4MSA4LjU3MDA0IDQuMTE0NTUgOC41MTQzNSA0LjIwNjcyIDguNDc2NjRDNC4yOTg5IDguNDM4OTMgNC4zOTc2NiA4LjQxOTk1IDQuNDk3MjQgOC40MjA4MUM0LjU5NjgyIDguNDIxNjggNC42OTUyNCA4LjQ0MjM3IDQuNzg2NzQgOC40ODE2OEM0Ljg3ODI1IDguNTIwOTggNC45NjEgOC41NzgxMiA1LjAzMDE5IDguNjQ5NzVMNy4zNzk5NCAxMC45OTk1TDEyLjk2OTcgNS40MDk3NUMxMy4xMTExIDUuMjczMTMgMTMuMzAwNiA1LjE5NzU0IDEzLjQ5NzIgNS4xOTkyNUMxMy42OTM5IDUuMjAwOTYgMTMuODgyIDUuMjc5ODMgMTQuMDIxMSA1LjQxODg5QzE0LjE2MDEgNS41NTc5NCAxNC4yMzkgNS43NDYwNSAxNC4yNDA3IDUuOTQyN0MxNC4yNDI0IDYuMTM5MzUgMTQuMTY2OCA2LjMyODggMTQuMDMwMiA2LjQ3MDI1TDcuOTEwMTkgMTIuNTkwM0M3Ljg0MDYzIDEyLjY2IDcuNzU3OTkgMTIuNzE1MyA3LjY2Njk5IDEyLjc1M0M3LjU3NTk5IDEyLjc5MDcgNy40Nzg0NCAxMi44MTAxIDcuMzc5OTQgMTIuODFaIiBmaWxsPSIjNTEyQjJCIi8+CjwvZz4KPC9zdmc+Cg==');
    background-position: center;
  }

  @media (hover: hover) {
    .item:hover & {
      border-color: $bodyTextColor;
    }
  }
}
