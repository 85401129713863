@import 'src/assets/css/vars.scss';

.heading {
  font-family: $fontSwedbankBlack;
  font-size: 32px;
  margin-bottom: 24px;
  color: $primaryAcentColor;
}

.mainText {
  line-height: 1.43;
  margin-bottom: 24px;
}

.footnote {
  font-size: 14px;
  line-height: 1.33;
  color: #896f6f;

  & a {
    color: $secondaryAcentColor;
    transition: color 0.13s linear;
  }

  @media (hover: hover) {
    & a:hover {
      color: $secondaryColor;
    }
  }
}

.footer {
  margin-top: 32px;
}
