@import 'src/assets/css/vars.scss';

.wrapper {
  margin-bottom: 42px;
}

.inner {
  display: inline-block;
  border-bottom: 4px solid $primaryAcentColor;
  font-family: $fontSwedbankBlack;
  font-size: 16px;

  & em {
    font-size: 32px;
    color: $primaryAcentColor;
    margin-right: 6px;
    line-height: 1.2;
  }

  & small {
    vertical-align: bottom;
    line-height: 1.3;
    padding-bottom: 4px;
    display: inline-block;
  }

  @media screen and (min-width: $screenMdMin) {
    font-size: 20px;

    & em {
      font-size: 36px;
    }

    & small {
      padding-bottom: 5px;
    }
  }
}
