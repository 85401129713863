@import 'src/assets/css/vars.scss';

.wrapper {
  height: 100%;
}

.label {
  display: block;
  font-size: 16px;
  margin-bottom: 6px;

  .wrapper.hasError & {
    color: $primaryAcentColor;
  }
}

.input {
  width: 100%;
  min-width: 1%;
  min-height: 100%;
  background-color: $whiteColor;
  border: 1px solid $lightBgColor;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 16px;
  line-height: 1.4;

  &:focus {
    outline: 2px auto #5b8ad6;
  }

  .wrapper.hasError & {
    border-color: $primaryAcentColor;
    color: $primaryAcentColor;
  }

  @media screen and (min-width: $screenMdMin) {
    padding: 26px 24px;
  }
}

.error {
  display: block;
  margin-top: 4px;
  font-size: 14px;
  color: $primaryAcentColor;
}
