@import 'src/assets/css/vars.scss';

.wrapper {
}

.input {
  border: none;
  border-radius: 0;
  background-color: $whiteColor;
  height: 32px;
  padding: 0 12px;
  min-width: 1%;
  width: 100%;
  max-width: 130px;
  font-size: 16px;
  text-align: center;
  transition: background-color 0.13s linear;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &:focus {
    outline: 2px auto #5b8ad6;
  }

  &.hasError {
    background-color: $roseColor;
  }
}
