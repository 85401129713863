@import 'src/assets/css/vars.scss';

.wrapper {
  width: 100%;
  max-width: 580px;
  background-color: $whiteColor;
  margin: 64px auto;
  padding: 48px 24px;

  @media screen and (min-width: $screenMdMin) {
    margin: 140px auto;
    padding: 48px 70px;
  }
}

.heading {
  font-family: $fontSwedbankBlack;
  font-size: 28px;
  line-height: 1.2;
  text-align: center;
  margin-bottom: 42px;

  @media screen and (min-width: $screenMdMin) {
    font-size: 36px;
    margin-bottom: 32px;
  }
}

.row + .row {
  margin-top: 32px;
}

.footnote {
  font-size: 14px;
  line-height: 1.4;
}

.footer {
  text-align: center;
  margin-top: 32px;

  & > * {
    margin: 12px auto;
  }
}
