@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

@font-face {
  font-family: 'SwedbankHeadline-Black';
  src: url('../fonts/SwedbankHeadline-Black.otf');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'SwedbankHeadline-Bold';
  src: url('../fonts/SwedbankHeadline-Bold.otf');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
