@import 'src/assets/css/vars.scss';

.wrapper {
  padding: 24px 24px;
  font-size: 18px;
  line-height: 1.3;
  margin: 48px 0;

  &.success {
    background-color: $skyColor;
  }

  &.info {
    background-color: $peachColor;
  }

  &.danger {
    background-color: $roseColor;
  }

  @media screen and (min-width: $screenMdMin) {
    padding: 36px 48px;
    font-size: 20px;
  }
}
