.innerHeading {
  font-family: $fontSwedbankBlack;
  font-size: 36px;
  line-height: 1.2;
  color: $primaryAcentColor;
}

.introText {
  font-size: 20px;
  line-height: 1.4;

  & b {
    font-weight: 700;
  }

  & a {
    color: $secondaryAcentColor;
    font-weight: 700;
  }
}

.subHeading {
  font-family: $fontSwedbankBlack;
  font-size: 30px;
  line-height: 1.3;

  & em {
    color: $primaryAcentColor;
  }
}

.textMd {
  font-size: 20px;
}

.textMedium {
  font-weight: 500;
}

.textBold {
  font-weight: 700;
}

.textOrange {
  color: $primaryAcentColor;
}

.dscr {
  white-space: pre-line;
  line-height: 1.4;
}
