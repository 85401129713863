@import 'src/assets/css/vars.scss';

.wrapper {
  margin-bottom: 42px;
}

.heading {
  font-family: $fontSwedbankBlack;
  font-size: 20px;
  line-height: 1.4;
  margin-bottom: 24px;

  @media screen and (min-width: $screenMdMin) {
    margin-bottom: 42px;
  }
}

.body {
  display: flex;
  flex-wrap: wrap;
}

.inputWrapper {
  width: 100%;

  @media screen and (max-width: $screenSmMax) {
    order: 2;
    margin-top: 24px;
  }

  @media screen and (min-width: $screenMdMin) {
    max-width: 50%;
  }

  @media screen and (min-width: $screenLgMin) {
    max-width: 580px;
  }
}

.hintWrapper {
  flex: 1;

  & h4 {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 18px;
  }

  & ol {
    line-height: 1.4;
    counter-reset: list;
  }

  & ol li {
    position: relative;
    counter-increment: list;
    padding-left: 24px;
  }

  & ol li:before {
    content: counter(list) '.';
    position: absolute;
    left: 0;
    top: 0;
    font-weight: 700;
  }

  & ol li + li {
    margin-top: 24px;
  }

  @media screen and (max-width: $screenSmMax) {
    order: 1;
  }

  @media screen and (min-width: $screenMdMin) {
    padding: 28px 0 28px 36px;

    & h4 {
      margin-bottom: 30px;
    }

    & ol li + li {
      margin-top: 38px;
    }
  }
}
