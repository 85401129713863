@import 'src/assets/css/vars.scss';

.wrapper {
  position: relative;
  border-top: 3px solid $brandColor;
  background-color: rgba($whiteColor, 0.9);
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;

  @media screen and (min-width: $screenLgMin) {
    width: $containerWidth;
  }
}

.inner {
  display: flex;
  align-items: center;
  padding: 16px 0;
}

.logo {
  width: 130px;
  height: 30px;

  @media screen and (min-width: $screenSmMin) {
    width: 152px;
    height: 34px;
  }
}

.title {
  font-family: $fontSwedbankBlack;
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: 0.16px;
  margin-left: 16px;

  @media screen and (min-width: $screenSmMin) {
    font-size: 16px;
    margin-left: 64px;
  }
}

.tools {
  display: flex;
  align-items: center;
  margin-left: auto;

  @media screen and (max-width: $screenSmMax) {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 99;
    width: 100%;
    background-color: $whiteColor;
    display: none;
    padding: 16px;

    &.isExpanded {
      display: block;
    }
  }
}

.username {
  font-weight: 500;
}

.signoutBtn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  font-weight: 500;
  font-size: 16px;
  color: $secondaryColor;
  padding: 0;
  transition: color 0.13s linear;

  @media (hover: hover) {
    &:hover {
      color: $secondaryAcentColor;
    }
  }
}

.sep {
  display: inline-block;
  width: 1px;
  height: 16px;
  background-color: $lightBgColor;
  margin: 0 12px;

  @media screen and (max-width: $screenSmMax) {
    width: 100%;
    height: 1px;
    margin: 12px 0;
  }
}

.mobileToggleContainer {
  margin-left: auto;

  @media screen and (min-width: $screenMdMin) {
    display: none;
  }
}

.mobileToggleBtn {
  display: block;
  width: 32px;
  height: 32px;
  font-size: 0;
  padding: 0 5px;

  & span {
    position: relative;
    display: block;
    width: 22px;
    height: 2px;
    background-color: currentcolor;
  }

  & span:before,
  & span:after {
    content: '';
    position: absolute;
    left: 0;
    width: 22px;
    height: 2px;
    background-color: currentcolor;
    transition: top 0.12s ease, transform 0.12s ease;
  }

  & span:before {
    top: -8px;
  }

  & span:after {
    bottom: -8px;
  }

  &.isExpanded span {
    background-color: transparent;
  }

  &.isExpanded span:before {
    top: 0;
    transform: rotate(45deg);
  }

  &.isExpanded span:after {
    bottom: 0;
    transform: rotate(-45deg);
  }
}
