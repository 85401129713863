@import 'src/assets/css/vars.scss';

.wrapper {
}

.label {
  display: block;
  font-size: 16px;
  margin-bottom: 6px;

  .wrapper.hasError & {
    color: $primaryAcentColor;
  }
}

.input {
  border: 1px solid $borderColor;
  border-radius: 4px;
  background-color: $whiteColor;
  height: 46px;
  padding: 0 12px;
  min-width: 1%;
  width: 100%;
  font-size: 16px;
  transition: border-color 0.13s linear, color 0.13s linear;

  &::-webkit-list-button {
    display: none !important;
    opacity: 0;
  }

  &:focus {
    outline: 2px auto #5b8ad6;
  }

  .wrapper.hasError & {
    border-color: $primaryAcentColor;
    color: $primaryAcentColor;
  }

  &.hasDatalist {
    padding-right: 30px;
    background-color: #ecf9f3;
    background-size: 18px 18px;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 28 28' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath id='dropdown.-traingle' d='m8.7174 15.485h10.804l-5.4022 9.6601z' stroke-width='1.5764'/%3E%3Cpath d='m19.632 11.771h-10.804l5.4022-9.6601z' stroke-width='1.5764'/%3E%3C/svg%3E");
    background-position: right -20px center;
    background-origin: content-box;
    background-repeat: no-repeat;
  }
}

.error {
  display: block;
  margin-top: 4px;
  font-size: 14px;
  color: $primaryAcentColor;
}
