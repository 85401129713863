@import 'src/assets/css/vars.scss';

.wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;

  & > * {
    width: 100%;
    margin: 8px 12px;
  }

  @media screen and (min-width: $screenSmMin) {
    margin: 0 -12px;

    & > * {
      width: auto;
    }
  }
}
