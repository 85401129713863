@import 'src/assets/css/vars.scss';

.wrapper {
  position: relative;
  width: 100%;
  margin-bottom: 42px;
}

.table {
  vertical-align: middle;
  border-collapse: collapse;
  font-size: 14px;

  @media screen and (max-width: $screenSmMax) {
    display: block;
    border-top: 2px solid $primaryColor;

    & thead {
      display: none;
    }

    & tbody {
      display: block;
      width: 100%;
    }

    & tbody tr {
      display: block;
      border-bottom: 1px solid $lightBgColor;
      padding: 12px 0;
    }

    & td {
      display: block;
      font-weight: 700;
    }

    & td + td {
      margin-top: 12px;
    }

    & td:before {
      content: attr(data-label);
      display: block;
      margin-bottom: 6px;
      font-weight: 400;
    }

    & td:first-child {
      font-weight: 700;
      font-size: 16px;
      padding-bottom: 12px;
    }

    & td input {
      max-width: 100%;
    }
  }

  @media screen and (min-width: $screenMdMin) {
    & th {
      font-weight: 700;
      line-height: 1.4;
      padding: 12px 0;
      border-bottom: 2px solid $primaryColor;
      text-align: left;
      vertical-align: top;
    }

    & td {
      padding: 16px 54px 16px 0;
      border-bottom: 1px solid $lightBgColor;
    }

    & td:nth-child(n + 2) {
      text-align: center;
    }

    & tr:last-child td {
      border-bottom: none;
    }
  }
}
