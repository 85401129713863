@import 'src/assets/css/vars.scss';

.wrapper {
  padding: 48px 24px;
}

.chartTitle {
  font-family: $fontSwedbankBlack;
  font-size: 20px;
  text-align: center;
  color: $primaryAcentColor;
}
