/* Fonts */
$fontSwedbankBold: 'SwedbankHeadline-Bold', Arial, Helvetica, sans-serif;
$fontSwedbankBlack: 'SwedbankHeadline-Black', Arial, Helvetica, sans-serif;
$fontRegular: 'Roboto', Arial, Helvetica, sans-serif;

/* Colors */
$bgColor: #fdf6ee;
$lightBgColor: #e5dcd7;
$bodyTextColor: #512b2b;
$primaryColor: #ff5f00;
$primaryAcentColor: #fb4f00;
$secondaryColor: #31a3ae;
$secondaryAcentColor: #257886;
$roseColor: #ffecff;
$skyColor: #dbf8ed;
$peachColor: #fff1cd;
$brandColor: #fc5906;
$whiteColor: #ffffff;
$borderColor: #bbead9;
$dangerColor: #c5131c;

/* Layout */
$containerWidth: 918px;

/* Breakpoints */
$screenLgMin: 1024px;
$screenMdMax: $screenLgMin - 1;
$screenMdMin: 768px;
$screenSmMax: $screenMdMin - 1;
$screenSmMin: 640px;
