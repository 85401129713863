@import 'src/assets/css/vars.scss';

.btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  height: 46px;
  min-width: 196px;
  padding: 0 16px;
  border-radius: 3px;
  font-family: $fontSwedbankBold;
  font-size: 18px;
  transition: opacity 0.13s linear, color 0.13s linear,
    background-color 0.13s linear;

  &.primary {
    background-color: $primaryColor;
    color: $whiteColor;
  }

  &.secondary {
    background-color: $secondaryColor;
    color: $whiteColor;
  }

  &.link {
    background-color: transparent;
    color: $primaryAcentColor;
    text-decoration: underline;
  }

  &.inline-link {
    background-color: transparent;
    color: $primaryAcentColor;
    text-decoration: underline;
    padding: 0;
    min-width: 1%;
    height: auto;
  }

  &.isLoading {
    color: transparent;
    pointer-events: none;
  }

  &.isLoading:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -11px 0 0 -11px;
    width: 16px;
    height: 16px;
    border-style: solid;
    border-width: 3px;
    border-color: rgba($whiteColor, 0.4);
    border-top-color: $whiteColor;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }

  @media (hover: hover) {
    &.primary:hover {
      background-color: $primaryAcentColor;
    }

    &.secondary:hover {
      background-color: $secondaryAcentColor;
    }

    &.link:hover {
      text-decoration: none;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
