.wrapper {
  height: 0;
  overflow: hidden;
  padding-top: 694px / 1512px * 100%;
  position: relative;
  margin-bottom: 48px;
}

.hero {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}
