@import 'src/assets/css/vars.scss';

.wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 24px 0 0 0;

  @media screen and (min-width: $screenMdMin) {
    flex-wrap: nowrap;
    padding: 56px 0;
  }
}

.legend {
  width: 100%;

  @media screen and (max-width: $screenSmMax) {
    order: 2;
    margin-top: 24px;
  }

  @media screen and (min-width: $screenMdMin) {
    width: 50%;
  }
}

.legend table td {
  padding-right: 0;
}

.legendInner {
  max-width: 270px;
  margin: auto;
}

.chartWrapper {
  width: 100%;
  text-align: center;

  @media screen and (max-width: $screenSmMax) {
    order: 1;
  }

  @media screen and (min-width: $screenMdMin) {
    width: 50%;
  }
}

.colorWrapper {
  position: relative;
  padding-left: 26px;
}

.color {
  position: absolute;
  top: 1px;
  left: 4px;
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.chartTitle {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.4;
  text-align: center;
  margin: 12px 0;
}
