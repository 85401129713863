@import 'src/assets/css/vars.scss';

.modal {
}

.backdrop {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.86);
}

.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 102;
  overflow: auto;
}

.container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100%;
}

.modal {
  position: relative;
  width: 574px;
  max-width: 100%;
  background-color: #fff;
  padding: 32px 16px 36px;
  margin: 54px 16px;

  @media screen and (min-width: $screenLgMin) {
    padding: 32px 24px 36px;
  }
}
