@import 'src/assets/css/vars.scss';

.wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -18px 24px -18px;

  @media screen and (min-width: $screenMdMin) {
    margin: 0 -18px 48px -18px;
  }

  @media screen and (min-width: 1200px) {
    width: 1026px;
  }
}

.section {
  padding: 18px;
  width: 100%;

  @media screen and (min-width: $screenMdMin) {
    &.sm {
      width: 40%;
    }

    &.md {
      width: 60%;
    }

    &.lg {
      width: 100%;
    }
  }
}

.card {
  background-color: $whiteColor;
  height: 100%;
}
