@import 'src/assets/css/vars.scss';

.wrapper {
}

.inner {
  padding: 60px 0;
  width: 100%;
  max-width: 886px;
}

.heading {
  font-family: $fontSwedbankBlack;
  font-size: 24px;
  line-height: 1.2;
  color: $primaryAcentColor;
  margin-bottom: 24px;

  @media screen and (min-width: $screenMdMin) {
    font-size: 36px;
    margin-bottom: 32px;
  }
}

.intro {
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: 42px;
  word-wrap: break-word;

  & b {
    font-weight: 700;
  }

  & a {
    color: $secondaryAcentColor;
    font-weight: 700;
  }

  @media screen and (min-width: $screenMdMin) {
    font-size: 20px;
  }
}

.footnote {
  font-family: $fontSwedbankBlack;
  font-size: 24px;
  line-height: 1.3;
  margin-bottom: 42px;

  & em {
    color: $primaryAcentColor;
  }

  @media screen and (min-width: $screenMdMin) {
    font-size: 30px;
  }
}

.warning {
  font-size: 20px;
  font-weight: 700;
  color: $primaryAcentColor;
  margin-bottom: 42px;
}
