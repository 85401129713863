@import 'src/assets/css/vars.scss';

.wrapper {
  width: 100%;
  max-width: 580px;
  background-color: $whiteColor;
  margin: 64px auto;
  padding: 48px 24px;

  @media screen and (min-width: $screenMdMin) {
    margin: 140px auto;
    padding: 48px 70px;
  }
}

.heading {
  font-family: $fontSwedbankBlack;
  font-size: 28px;
  line-height: 1.3;
  text-align: center;
  margin-bottom: 32px;
  color: $primaryAcentColor;

  @media screen and (min-width: $screenMdMin) {
    font-size: 30px;
  }
}

.text {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.4;
  text-align: center;

  @media screen and (min-width: $screenMdMin) {
    font-size: 20px;
  }
}

.footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 32px -12px 0 -12px;

  & > * {
    width: 100%;
    margin: 8px 12px;
  }

  @media screen and (min-width: $screenSmMin) {
    & > * {
      width: auto;
    }
  }
}
