@import 'src/assets/css/vars.scss';

.heading {
  font-family: $fontSwedbankBlack;
  font-size: 32px;
  margin-bottom: 24px;
  color: $primaryAcentColor;
}

.footer {
  margin-top: 32px;
}

.successMsg {
  background-color: $skyColor;
  margin: 16px 0;
  padding: 16px;
}
