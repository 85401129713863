@import 'src/assets/css/vars.scss';

.wrapper {
  width: 100%;
  max-width: 784px;

  & h1 {
    font-family: $fontSwedbankBlack;
    font-size: 32px;
    line-height: 1.2;
    letter-spacing: -0.96px;
    color: $primaryAcentColor;
    margin-bottom: 24px;
  }

  & p,
  & ol {
    font-size: 18px;
    line-height: 1.33;
  }

  & ol {
    counter-reset: list;
  }

  & ol li {
    position: relative;
  }

  & ol li {
    position: relative;
    counter-increment: list;
    padding-left: 24px;
    word-wrap: break-word;
  }

  & ol li:before {
    content: counter(list) '.';
    position: absolute;
    left: 0;
    top: 0;
    font-weight: 700;
  }

  & ol li + li {
    margin-top: 24px;
  }

  & a {
    font-weight: 700;
    text-decoration: underline;
    color: $secondaryAcentColor;
  }

  & b {
    font-weight: 700;
  }

  @media screen and (min-width: $screenLgMin) {
    & h1 {
      font-size: 48px;
      line-height: 1.5;
      margin-bottom: 24px;
    }

    & p,
    & ol {
      font-size: 20px;
      line-height: 1.4;
    }

    & ol li {
      padding-left: 32px;
    }
  }
}
