@import 'src/assets/css/vars.scss';

.table {
  width: 100%;

  & th {
    font-weight: 500;
    text-align: left;
  }

  & th,
  & td {
    padding: 12px 0;
    border-bottom: 1px solid $lightBgColor;
  }

  & a {
    color: $secondaryAcentColor;
  }
}
